import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import AddIcon from '@mui/icons-material/Add';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Stack,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridEditInputCell,
  GridEditSingleSelectCell,
  GridExpandMoreIcon,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid';

import { operations } from '@luxuryescapes/contract-svc-promo';

import { DEFAULT_PAGE_SIZES } from '~/consts/filters';

import useQuery from '~/hooks/useQuery';

import {
  InternalPromoWithDiscounts,
  PromoCondition,
  PromoConditionRaw,
  PromoMeta,
  createOrUpdatePromoCondition,
  deletePromoCodeCondition,
} from '~/services/PromoService';

import dateFormatterDetailed from '~/utils/dateFormatterDetails';

import PageSubheader from '../Common/Elements/PageSubheader';
import HeaderInfo from '../Common/HeaderInfo';
import Spinner from '../Common/Spinner';
import DebugModal from '../DebugModal/DebugModal';
import MultiDiscountSelect, { renderMultiDiscountValue } from '../PromoV3/MultiDiscountSelect';

import PromoCodeUser from './formatters/PromoCodeUser';

interface Props {
  promoCode: InternalPromoWithDiscounts;
  promoMeta?: PromoMeta;
  onConditionRowSelect: (condition: PromoCondition | null) => void;
}

const addUniqueID = (condition: PromoConditionRaw, promoMeta: PromoMeta): PromoCondition => {
  const existing = 'id_promo_code_condition' in condition;
  return {
    ...condition,
    id: condition.id_promo_code_condition ?? uuid(),
    isNew: !existing,
    config: promoMeta?.promo_condition_types.find((ct) => ct.key === condition.condition_type),
  };
};

function PromoConditions({ promoCode, promoMeta, onConditionRowSelect }: Props) {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const { enqueueSnackbar } = useSnackbar();
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const query = useQuery();
  const isDev = query.get('dev') === 'true';

  const [conditions, setConditions] = useState<Array<PromoCondition>>(
    promoCode.promo_conditions.map((condition) => addUniqueID(condition, promoMeta)) ?? [],
  );

  const handleAddCondition = () => {
    const newCondition = addUniqueID(
      {
        id_promo_code: promoCode?.id_promo_code,
        condition_type: promoMeta[0]?.promo_condition_types[0]?.key,
        isNew: true,
        brand: brand,
      } as unknown as PromoConditionRaw,
      promoMeta,
    );
    setConditions([...conditions, newCondition]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newCondition.id]: { mode: GridRowModes.Edit, fieldToFocus: 'region' },
    }));
  };

  const handleCancelCondition = useCallback(
    (params: PromoCondition) => {
      if (params?.isNew) {
        setConditions(
          conditions.filter(
            (row) => row.id !== params.id && row.id_promo_code_condition !== params.id_promo_code_condition,
          ),
        );
      } else {
        setRowModesModel({
          ...rowModesModel,
          [params.id_promo_code_condition]: { mode: GridRowModes.View, ignoreModifications: true },
        });
      }
      onConditionRowSelect(null);
    },
    [rowModesModel, setConditions, conditions],
  );

  const handleEditCondition = useCallback(
    (params: PromoCondition) => {
      setRowModesModel({
        ...rowModesModel,
        [params?.id ?? params?.id_promo_code_condition]: { mode: GridRowModes.Edit, fieldToFocus: 'region' },
      });
      onConditionRowSelect(params);
    },
    [promoMeta, rowModesModel],
  );

  const handleDeleteCondition = async (params: PromoCondition) => {
    if (confirm('are you sure you want to delete this condition?')) {
      try {
        await deletePromoCodeCondition(params.id_promo_code_condition);
        enqueueSnackbar('Condition deleted successfully');
        setConditions(conditions.filter((row) => row.id_promo_code_condition !== params.id_promo_code_condition));
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
      onConditionRowSelect(null);
    }
  };

  const handleSaveCondition = async (params: PromoCondition) => {
    setRowModesModel({
      ...rowModesModel,
      [params?.id ?? params.id_promo_code_condition]: { mode: GridRowModes.View },
    });
    onConditionRowSelect(null);
  };

  const processRowUpdate = async (row: PromoCondition) => {
    try {
      if (row.condition_type == 'LUX-PROMO-USER-REQUIRED') {
        row.condition_value = 'true';
      }
      const payload: operations['createOrUpdatePromoCodeCondition']['parameters']['body']['payload'] = {
        promoCodeCondition: {
          id_promo_code_condition: row.id_promo_code_condition,
          condition_type: row.condition_type,
          condition_value: typeof row.condition_value === 'string' ? row.condition_value : row.condition_value ?? '',
          id_promo_code: row.id_promo_code,
          promo_code_discount_ids: row.promo_code_discount_ids ?? [],
        },
      };

      const result = await createOrUpdatePromoCondition(payload);
      if (result.status !== 200) {
        const error = new Error(JSON.stringify(result.result));
        enqueueSnackbar(JSON.stringify(result.result), {
          autoHideDuration: 5000,
        });
        throw error;
      } else {
        const newCondition = result.result.promoCodeCondition;
        const newConditionArray = conditions.map((c) => {
          if (c.id_promo_code_condition === newCondition.id_promo_code_condition) {
            return addUniqueID(newCondition, promoMeta);
          } else if (c.id === row.id) {
            return addUniqueID(newCondition, promoMeta);
          }
          return c;
        });

        setConditions(newConditionArray);
        enqueueSnackbar('Condition saved successfully', { variant: 'success' });

        return addUniqueID(newCondition, promoMeta);
      }
    } catch (err) {
      console.error(
        `processConditionRowUpdate - ${row?.id ?? row.id_promo_code_condition} - error\n${err.message}\n${
          err.stack
        }\n\n ${JSON.stringify(row)}`,
      );
      enqueueSnackbar(JSON.stringify(err), {
        autoHideDuration: 5000,
      });
      throw err;
    }
  };

  const handleRowClick = (params: PromoCondition) => {
    onConditionRowSelect(params);
  };

  const sharedOpts: Partial<GridColDef<PromoCondition>> = {
    editable: true,
    sortable: true,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    flex: 1,
  };

  const getColumns = (): Array<GridColDef<PromoCondition>> => [
    {
      ...sharedOpts,
      field: 'condition_type',
      headerName: 'Condition Type',
      type: 'singleSelect',
      renderCell: (params) => {
        if (!params.row.config) {
          return <Typography>{params.row.condition_type} (config missing)</Typography>;
        }
        return (
          <Accordion>
            <AccordionSummary expandIcon={<GridExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
              <Typography>{params.row.config?.name}</Typography>
              {params.row.config?.isAvailableInAPP ? '' : '[Behavior not yet tested via APP]'}
            </AccordionSummary>
            <AccordionDetails>
              <Box margin={1}>
                <Typography>{params.row.config?.longDescription}</Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      },
      valueOptions:
        promoMeta?.promo_condition_types.map((ct) => {
          return {
            value: ct.key,
            label: ct.name,
          };
        }) ?? [],
      renderEditCell: (params) => {
        return (
          <Stack direction="column">
            <GridEditSingleSelectCell {...params} value={params.row.condition_type} />
            {params.row.config?.longDescription && (
              <Box padding={1}>
                {params.row.config.longDescription}{' '}
                {params.row.config.isAvailableInAPP ? '' : '[Behavior not yet tested via APP]'}
              </Box>
            )}
          </Stack>
        );
      },
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: 2,
    },
    {
      ...sharedOpts,
      field: 'condition_value',
      headerName: 'Condition Value',
      renderEditCell: (params) => <GridEditInputCell {...params} required />,
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
    },
    {
      ...sharedOpts,
      field: 'promo_code_discount_ids',
      renderHeader: () => (
        <HeaderInfo
          title="When discounts are specified here, the discounts will only apply to the order only when the associated condition(s) are met"
          header="Discount Filter"
          iconType="info"
        />
      ),
      renderCell: (params) =>
        renderMultiDiscountValue(params.row.promo_code_discount_ids ?? [], promoCode.promo_discounts),
      renderEditCell: (params) => {
        if (!promoMeta) {
          return <Spinner />;
        }
        if (!params.row.config?.canUseDiscountFilterIds) {
          return null;
        }
        return (
          <MultiDiscountSelect
            id={`${params.id}`}
            value={params.row.promo_code_discount_ids ?? []}
            promoDiscounts={promoCode.promo_discounts}
            multiSelectType="promo_code_discount_ids"
          />
        );
      },
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
    },
    {
      ...sharedOpts,
      sortable: false,
      editable: false,
      field: 'action',
      headerName: 'Action',
      renderCell: (params) => {
        if (rowModesModel[params.id]?.mode === GridRowModes.Edit) {
          return (
            <>
              <ButtonGroup variant="contained" size="small" disableElevation>
                <Button key={`${params.id}-save`} onClick={() => handleSaveCondition(params.row)}>
                  Save
                </Button>
                <Button key={`${params.id}-cancel`} onClick={() => handleCancelCondition(params.row)}>
                  Cancel
                </Button>
              </ButtonGroup>
              <Typography>{dateFormatterDetailed({ date: params.row.created_at })}</Typography>
              <Typography>{dateFormatterDetailed({ date: params.row.updated_at })}</Typography>
              <PromoCodeUser userId={params.row.modified_by} />
              {params.row.id_promo_code_condition && (
                <ButtonGroup>
                  <Button key={`${params.id}-delete`} onClick={() => handleDeleteCondition(params.row)}>
                    Delete
                  </Button>
                </ButtonGroup>
              )}
            </>
          );
        }
        return (
          <ButtonGroup variant="text" size="small">
            <Button key={`${params.id}-edit`} onClick={() => handleEditCondition(params.row)}>
              Edit
            </Button>
          </ButtonGroup>
        );
      },
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
    },
    {
      field: 'debug',
      headerName: 'Info',
      renderCell: (params) => {
        if (!promoMeta) {
          return null;
        }
        const data = {
          data: params.row,
          config: promoMeta.promo_condition_types.find((ct) => ct.key === params.row.condition_type),
        };
        return <DebugModal type="generic" data={data ?? { data: 'None' }} />;
      },
      ...sharedOpts,
      flex: 0,
    },
  ];

  return (
    <Box mt={3}>
      <PageSubheader title="Conditions">
        <Stack direction="row">
          <Typography variant="h5" pr={2}></Typography>
          {isDev && <DebugModal type="generic" data={{ promoMeta, conditions }} />}
          <Button onClick={handleAddCondition} variant="contained" size="small" startIcon={<AddIcon />}>
            Add Condition
          </Button>
        </Stack>
      </PageSubheader>
      {promoMeta ? (
        <DataGrid
          columns={getColumns()}
          editMode="row"
          autoHeight
          rows={conditions}
          rowModesModel={rowModesModel}
          initialState={{ pagination: { paginationModel: { pageSize: 10, page: 0 } } }}
          pageSizeOptions={DEFAULT_PAGE_SIZES}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => {
            enqueueSnackbar(error.message, { autoHideDuration: 5000 });
          }}
          getRowId={(row) => row.id_promo_code_condition ?? row.id}
          onRowEditStop={(params) => handleCancelCondition(params.row)}
          onRowClick={(params) => handleRowClick(params.row)}
          getRowHeight={() => 'auto'}
        />
      ) : (
        <Spinner />
      )}
    </Box>
  );
}

export default PromoConditions;
