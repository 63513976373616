export enum PriceSummaryJobStatus {
  PENDING = 'PENDING',
  INITIALISING = 'INITIALISING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  RETRYING = 'RETRYING',
  CANCELLED = 'CANCELLED',
}

export type PriceSummaryJob = {
  jobId: string;
  status: PriceSummaryJobStatus;
  createdAt: string;
  updatedAt: string;
  locationUrl: string;
  startDate: string;
  endDate: string;
  numberOfNights: number;
  currencyCode: string;
  countryCode: string;
  progress: number;
  days: number;
  submittedBy?: string;
  submittedByEmail?: string;
  cancelledBy?: string;
  cancelledByEmail?: string;
  retries: number;
};

export type PriceSummaryJobBody = {
  location_url: string;
  start_date: string;
  end_date: string;
  number_of_nights: number;
  currency_code: string;
  country_code: string;
};

export type PaginatedPriceParrotQueryParams = {
  page?: number;
  limit?: number;
};
export type PriceSummaryJobQueryParams = PaginatedPriceParrotQueryParams & {
  user_id?: string;
};

export type PaginatedPriceParrotResponse<T> = {
  data: Array<T>;
  pagination: {
    page: number;
    limit: number;
    total: number;
    totalPages: number;
  };
};

export enum ScheduleInterval {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  ADHOC = 'ADHOC',
}

export enum ScheduledPriceSummaryJobStatus {
  PAUSED = 'PAUSED',
  ACTIVE = 'ACTIVE',
}

export type Property = {
  id: number;
  name?: string;
  location?: string;
  url: string;
};

export type ScheduledPriceSummaryJobVariation = {
  id: number;
  countryCode: string;
  currencyCode: string;
  numberOfNights: number;
  offsetDays: number;
  totalDays: number;
  createdAt: string;
};
export type ScheduledPriceSummaryJobVariationBody = Omit<ScheduledPriceSummaryJobVariation, 'id' | 'createdAt'>;

export type ScheduledPriceSummaryJob = {
  id: number;
  property: Property;
  status: ScheduledPriceSummaryJobStatus;
  scheduleInterval: ScheduleInterval;
  nextRunAt: string;
  createdBy?: string;
  createdByEmail?: string;
  updatedBy?: string;
  updatedByEmail?: string;
  createdAt: string;
  updatedAt: string;
  variations: Array<ScheduledPriceSummaryJobVariation>;
};

export type ScheduledPriceSummaryJobBody = {
  scheduleInterval: ScheduleInterval;
  locationUrl: string;
  scheduledJobVariations: Array<ScheduledPriceSummaryJobVariationBody>;
};

export type ScheduledPriceSummaryJobRun = {
  jobId: number;
  variation: ScheduledPriceSummaryJobVariation;
  status: PriceSummaryJobStatus;
  createdAt: string;
  updatedAt: string;
  retries: number;
  progress: number;
  days: number;
  errorMessage?: string;
};
