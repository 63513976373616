import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import {
  Alert,
  AlertColor,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import * as PromoContract from '@luxuryescapes/contract-svc-promo';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import { PaymentsServiceProviders } from '~/consts/payment';

import {
  InternalPromoWithDiscounts,
  PromoCondition,
  PromoMeta,
  PromoProductTypes,
  PromoRegionGroupings,
  addPromo,
  createOrUpdatePromoDiscount,
  getPromoMeta,
  hasAdvanceRestrictions,
  updatePromo,
} from '~/services/PromoService';
import { addYears, formatDateLongISO, formatDateLongMonthWithMeridiemNoSeconds } from '~/services/TimeService';

import { getPromoTagArrays } from '~/utils/promoTags';

import { PROMO_DEVICE_TYPES, PROMO_TYPES } from '../../consts/promo';
import DateTimeWidget from '../Common/Elements/DateTimeWidget';
import Spinner from '../Common/Spinner';
import UserName from '../Common/UserName';
import DebugModal from '../DebugModal/DebugModal';
import PromoConditions from '../Promo/PromoConditions';
import { PromoReplacementExplainer } from '../Promo/PromoReplacementExplainer';
import PromoUserBatchUpload from '../Promo/PromoUser/PromoUserBatchUpload';
import PromoUsers from '../Promo/PromoUsers';

import PromoDiscountFormV4 from './PromoDiscountFormV4';

interface PromoFormType {
  code_name: string;
  description: string;
  department_tag: string;
  promo_type: 'percentage' | 'fixed_amount';
  expires_at: Date;
  code_limit: number;
  first_time_purchase_only: boolean;
  first_time_app_purchase_only: boolean;
  limit_per_user: number;
  limit_per_user_per_year: number;
  device_types: Array<string>;
  allowed_payment_methods: Array<string>;
  allowed_airline_carriers: Array<string>;
  is_corporate?: string;
  corporate_name?: string;
  email_domains?: string;
  email_addresses?: string;
  bin_numbers?: string;
  offer_whitelist?: string;
  offer_blacklist?: string;
  dept_tag: string;
  category_tag: string;
  sub_category_tag: string;
  promo_toggles: Array<PromoContract.definitions['PromoToggles']['key']>;
  popup_message: string;
}

// Having a presenter to ensure we only send relevant data when update
const promoPresenter = (data: PromoFormType, brand: string) => {
  return {
    code_name: data.code_name,
    description: data.description,
    promo_type: data.promo_type,
    expires_at: data.expires_at,
    code_limit: data.code_limit,
    limit_per_user: data.limit_per_user,
    limit_per_user_per_year: data?.limit_per_user_per_year || 0,
    first_time_purchase_only: data.first_time_purchase_only,
    first_time_app_purchase_only: data.first_time_app_purchase_only,
    device_types: data.device_types,
    allowed_payment_methods: data.allowed_payment_methods,
    is_corporate: data.is_corporate,
    corporate_name: data.is_corporate ? data.corporate_name : undefined,
    email_domains: data.is_corporate ? data.email_domains.split(',').map((email) => email.trim()) : undefined,
    brand,
    email_addresses: data?.email_addresses ? data?.email_addresses?.split(',')?.map((email) => email?.trim()) : [],
    offer_whitelist: data?.offer_whitelist ? data?.offer_whitelist?.split(',')?.map((offer) => offer?.trim()) : [],
    offer_blacklist: data?.offer_blacklist ? data?.offer_blacklist?.split(',')?.map((offer) => offer?.trim()) : [],
    bin_numbers: data?.bin_numbers ? data?.bin_numbers?.split(',')?.map((bin) => bin?.trim()) : [],
    dept_tag: data.dept_tag,
    category_tag: data.category_tag,
    sub_category_tag: data.sub_category_tag,
    promo_toggles: data.promo_toggles ?? [],
    popup_message: data.popup_message,
    allowed_airline_carriers: data?.allowed_airline_carriers ?? [],
  };
};

interface Props {
  promoCode: InternalPromoWithDiscounts | null;
  parentPromo?: InternalPromoWithDiscounts | null;
  rerender?: () => void;
  promoMeta: PromoMeta;
  isDev: boolean;
}

const formDefaultValues = {
  code_name: null,
  description: null,
  department_tag: null,
  promo_type: null,
  expires_at: formatDateLongISO(addYears(1)),
  code_limit: 1,
  limit_per_user: 1,
  limit_per_user_per_year: null,
  first_time_purchase_only: false,
  first_time_app_purchase_only: false,
  device_types: [],
  allowed_payment_methods: [],
  allowed_airline_carriers: [],
  is_corporate: false,
  usage_count: null,
  corporate_name: null,
  email_domains: null,
  email_addresses: null,
  offer_whitelist: null,
  offer_blacklist: null,
  bin_numbers: null,
  dept_tag: null,
  category_tag: null,
  sub_category_tag: null,
  promo_toggles: [],
  popup_message: '',
};

function PromoFormV3({ promoCode = null, parentPromo, rerender, promoMeta, isDev }: Props) {
  const { control, handleSubmit, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues: promoCode
      ? {
          ...promoCode,
          department_tag: promoCode?.department_tag,
          dept_tag: promoCode?.dept_tag,
          category_tag: promoCode?.category_tag,
          sub_category_tag: promoCode?.sub_category_tag,
          expires_at: promoCode?.expires_at,
          usage_count: promoCode?.usage_count,
          first_time_purchase_only: promoCode?.first_time_purchase_only,
          first_time_app_purchase_only: promoCode?.first_time_app_purchase_only,
          email_domains: promoCode?.email_domains?.join(',') ?? '',
          email_addresses: promoCode?.email_addresses?.join(',') ?? '',
          offer_whitelist: promoCode?.offer_whitelist?.join(',') ?? '',
          offer_blacklist: promoCode?.offer_blacklist?.join(',') ?? '',
          bin_numbers: promoCode?.bin_numbers?.join(',') ?? '',
          promo_toggles: promoCode?.promo_toggles,
          allowed_airline_carriers: promoCode?.allowed_airline_carriers ?? [],
        }
      : formDefaultValues,
  });
  const [isDiscountReady, setIsDiscountReady] = useState(false);
  const [promoDiscountData, setPromoDiscountData] = useState<Array<App.PromoDiscount>>(
    promoCode?.promo_discounts ?? [],
  );
  const [selectedDiscountIds, setSelectedDiscountIds] = useState<string[]>([]);
  const [promoType, setPromoType] = useState(promoCode?.promo_type ?? undefined);

  const [promoTags, setPromoTags] = useState<Array<App.PromoTag>>(null);
  const [promoMetaLoading, setPromoMetaLoading] = useState<boolean>(true);

  const [promoDeptTagOptions, setPromoDeptTagOptions] = useState<Array<string>>([]);
  const [promoDeptTag, setPromoDeptTag] = useState<string>(promoCode?.dept_tag);
  const [promoCategoryTagOptions, setPromoCategoryTagOptions] = useState<Array<string>>([]);
  const [promoCategoryTag, setPromoCategoryTag] = useState<string>(promoCode?.category_tag);
  const [promoSubCategoryTagOptions, setPromoSubCategoryTagOptions] = useState<Array<string>>([]);
  const [promoSubCategoryTag, setPromoSubCategoryTag] = useState<string>(promoCode?.sub_category_tag ?? '');
  const [promoToggles, setPromoToggles] = useState<Array<PromoContract.definitions['PromoToggles']>>([]);

  const [promoProductTypes, setPromoProductTypes] = useState<PromoProductTypes>([]);
  const [promoRegionGroupings, setPromoRegionGroupings] = useState<PromoRegionGroupings>([]);
  const [parentPromoDisplay, setParentPromoDisplay] = useState<string>(promoCode?.id_promo_code);

  const isCorporateBenefitOn = watch('is_corporate');
  const promoDescription = watch('description');

  const promoDepartmentTag = watch('department_tag');
  const offerWhiteList = watch('offer_whitelist');
  const offerBlackList = watch('offer_blacklist');
  const firstTimePurchaseOnly = watch('first_time_purchase_only');
  const firstTimeAppPurchaseOnly = watch('first_time_app_purchase_only');
  const deviceTypes = watch('device_types');
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const hasAdvancedRestrictions = hasAdvanceRestrictions(promoCode);
  const [showAdvanced, setShowAdvanced] = useState(hasAdvancedRestrictions);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { search } = useLocation();

  const boxShadowStyle = {
    paper: {
      sx: {
        boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)`,
      },
    },
  };

  const [submitProblems, setSubmitProblems] = useState<Array<string>>([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const validateSubmission = () => {
    if (submitProblems.length > 0) {
      enqueueSnackbar(submitProblems.join(', '));
      return false;
    }
    return true;
  };

  const onSubmit = async (data) => {
    setHasSubmitted(true);
    if (!validateSubmission()) {
      return;
    }
    try {
      const filteredData = promoPresenter(data, brand);
      if (promoCode) {
        await updatePromo(promoCode.id_promo_code, brand, filteredData);
        rerender();
      } else {
        const newPromoCode = await addPromo(filteredData);
        // Create promo discounts following the promo code
        Promise.all(
          promoDiscountData.map(async (discount) => {
            const payload = {
              id_promo_code: newPromoCode.result.id_promo_code,
              id_promo_code_discount: discount.id_promo_code_discount,
              discount_value: discount.discount_value,
              max_discount: discount.max_discount,
              subscription_item_discount_value: discount.subscription_item_discount_value,
              subscription_item_discount_type: discount.subscription_item_discount_type,
              min_spend: discount.min_spend,
              products: discount.products,
              region: discount.region,
            };
            return await createOrUpdatePromoDiscount(payload);
          }),
        );
        setTimeout(() => history.push(`/promos/${newPromoCode.result.id_promo_code}${search}`), 5000);
      }
      enqueueSnackbar(
        `Success: ${
          promoCode
            ? 'Your changes have been saved.'
            : 'Your promo code has been created. You will now be redirected to the edit page.'
        }`,
        {
          autoHideDuration: 5000,
        },
      );
    } catch (err) {
      if ('errors' in err && err.errors?.length > 0) {
        enqueueSnackbar(`Error: ${JSON.stringify(err, null, 4)}`, {
          variant: 'error',
          autoHideDuration: 5000,
        });
      } else {
        enqueueSnackbar(`${err.message}`, {
          autoHideDuration: 5000,
        });
      }
    }
  };

  useEffect(() => {
    if (!isCorporateBenefitOn) {
      setValue('corporate_name', '');
      setValue('email_domains', '');
    }
  }, [isCorporateBenefitOn, setValue]);

  useEffect(() => {
    if (parentPromo && 'code_name' in parentPromo) {
      setParentPromoDisplay(parentPromo?.code_name);
    }
  }, [parentPromo]);

  useEffect(() => {
    const errors = [];

    if (!promoCode) {
      return;
    }

    if (!promoDiscountData.length) {
      errors.push('Add at least one Discount');
    }

    if (!promoDescription?.length) {
      errors.push('Add a Description');
    }
    if (promoDeptTag?.length == 0) {
      errors.push('Add a Department Tag');
    }
    if (promoCategoryTag?.length == 0 && promoCategoryTagOptions.length > 0) {
      errors.push('Add a Category');
    }
    if (promoSubCategoryTag?.length == 0 && promoSubCategoryTagOptions.length > 0) {
      errors.push('Add a Sub Category');
    }

    if (firstTimePurchaseOnly && firstTimeAppPurchaseOnly) {
      errors.push('Error: You cannot select both first time purchase checkboxes. Please try again.');
    }

    if (firstTimeAppPurchaseOnly && !(deviceTypes.includes('android') || deviceTypes.includes('ios'))) {
      errors.push(
        'Error: (when using the first time app purchase only checkbox) You must restrict to at least iOS app and/or Android app.',
      );
    }

    setSubmitProblems(errors);
  }, [
    promoCode?.promo_type,
    promoCode?.code_name,
    promoCode?.description,
    promoCode?.department_tag,
    promoCode?.created_at,
    promoDiscountData.length,
    promoDescription?.length,
    promoDepartmentTag?.length,
    promoDeptTag?.length,
    promoCategoryTag?.length,
    promoCategoryTagOptions?.length,
    promoSubCategoryTag?.length,
    promoSubCategoryTagOptions?.length,
    isDiscountReady,
    firstTimePurchaseOnly,
    firstTimeAppPurchaseOnly,
    deviceTypes,
    promoDiscountData,
    promoCode,
  ]);

  useEffect(() => {
    const fetchMeta = async () => {
      setPromoMetaLoading(true);
      await getPromoMeta()
        .then((res) => {
          setPromoTags(res.result.promo_tags);
          setPromoToggles(res.result.promo_toggles);
          refreshMetaOptions(res.result.promo_tags, promoCode?.dept_tag, promoCode?.category_tag);
          setPromoProductTypes(res.result.promo_product_types);
          setPromoRegionGroupings(res.result.promo_region_groupings);
          return res;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setPromoMetaLoading(false);
        });
    };
    fetchMeta();
  }, []);

  const refreshMetaOptions = (promoTags: Array<App.PromoTag>, selectedDepartment: string, selectedCategory: string) => {
    const tagArrays = getPromoTagArrays({ promoTags, selectedDepartment, selectedCategory });
    setPromoDeptTagOptions(tagArrays.departmentTags);
    setPromoCategoryTagOptions(tagArrays.categoryTags);
    setPromoSubCategoryTagOptions(tagArrays.subCategoryTags);
    if (tagArrays.categoryTags.length == 1) {
      setPromoCategoryTag(tagArrays.categoryTags[0]);
      setValue('category_tag', tagArrays.categoryTags[0]);
    } else if (tagArrays.categoryTags.length == 0) {
      setPromoCategoryTag('');
      setValue('category_tag', '');
    }
    if (tagArrays.subCategoryTags.length == 1) {
      setPromoSubCategoryTag(tagArrays.subCategoryTags[0]);
      setValue('sub_category_tag', tagArrays.subCategoryTags[0]);
    } else if (tagArrays.subCategoryTags.length == 0) {
      setPromoSubCategoryTag('');
      setValue('sub_category_tag', '');
    }
  };

  const createdAt = formatDateLongMonthWithMeridiemNoSeconds(promoCode?.created_at);
  const updatedAt = formatDateLongMonthWithMeridiemNoSeconds(promoCode?.updated_at);

  const handleConditionRowSelect = (condition: PromoCondition | null) => {
    setSelectedDiscountIds(condition?.promo_code_discount_ids || []);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {promoCode?.promo_warnings?.length > 0 && (
        <Grid container spacing={2}>
          {promoCode.promo_warnings.map((warning) => (
            <Grid item xs={12} key={`${warning.code}-${warning.id_promo_code}-${warning.id_promo_code_discount}`}>
              <Alert severity={warning.level as AlertColor}>{warning.code}</Alert>
            </Grid>
          ))}
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageSubheader title="Promo code details">
            {promoCode && promoCode.cloned_from_promo_id && (
              <Link target="_blank" to={`/promos/${promoCode.cloned_from_promo_id}`}>
                {`[child of ${parentPromoDisplay}]`}
              </Link>
            )}
            {promoCode && (
              <DebugModal type="generic" title="Raw Promo Data" data={JSON.parse(JSON.stringify(promoCode))} />
            )}
            {parentPromo && (
              <DebugModal type="generic" title="Raw Parent Promo Data" data={JSON.parse(JSON.stringify(parentPromo))} />
            )}
            <Stack direction="row" gap={1}>
              {promoCode && promoCode.added_by && <UserName asLink userId={promoCode.added_by} />}
              {promoCode && promoCode.created_at && <Typography variant="subtitle2">@</Typography>}
              {promoCode && promoCode.created_at && (
                <Typography variant="subtitle2" title="(created at)">
                  {createdAt}
                </Typography>
              )}
            </Stack>
            {promoCode && promoCode.updated_at && updatedAt != createdAt && (
              <Typography variant="subtitle2" title="(updated at)">
                [{updatedAt}]
              </Typography>
            )}
          </PageSubheader>
        </Grid>
        <Controller
          key="code_name"
          name="code_name"
          control={control}
          render={({ field, fieldState }) => (
            <Grid item xs={6}>
              <TextField
                {...field}
                fullWidth
                label="Promo Code Name"
                required
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : ''}
              />
            </Grid>
          )}
          rules={{
            required: 'Promo code name is required',
            pattern: {
              value: /^[A-Za-z0-9_-]+$/,
              message: 'Promo code name can only include alphanumeric characters, underscores, and dashes',
            },
          }}
        />
        <Controller
          key="promo_type"
          name="promo_type"
          control={control}
          render={({ field }) => (
            <Grid item xs={6}>
              <Autocomplete
                {...field}
                disabled={!!promoCode}
                options={PROMO_TYPES.map((type) => type.key)}
                getOptionLabel={(option) => PROMO_TYPES.find((type) => type.key === option)?.value}
                onChange={(e, newValue) => {
                  setPromoType(newValue);
                  field.onChange(newValue);
                }}
                renderInput={(params) => <TextField {...params} required variant="outlined" label="Promo Type" />}
                componentsProps={boxShadowStyle}
              />
            </Grid>
          )}
          rules={{ required: true }}
        />
        <Controller
          key="description"
          name="description"
          control={control}
          render={({ field }) => (
            <Grid item xs={12}>
              <TextField {...field} fullWidth label="Description" required />
            </Grid>
          )}
          rules={{ required: true }}
        />
        {promoMetaLoading && <Spinner />}
        {!promoMetaLoading && promoDeptTagOptions.length > 0 && (
          <Controller
            key="dept_tag"
            name="dept_tag"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Grid item xs={4}>
                <Autocomplete
                  {...field}
                  value={promoDeptTag}
                  options={promoDeptTagOptions}
                  renderInput={(params) => <TextField required {...params} variant="outlined" label="Department" />}
                  onChange={(e: unknown, newValue: string | null) => {
                    field.onChange(newValue);
                    setPromoDeptTag(newValue);
                    setPromoCategoryTag(null);
                    setPromoSubCategoryTag(null);
                    refreshMetaOptions(promoTags, newValue, null);
                  }}
                  componentsProps={boxShadowStyle}
                />
              </Grid>
            )}
          />
        )}
        {!promoMetaLoading && promoCategoryTagOptions.length > 0 && (
          <Controller
            key="category_tag"
            name="category_tag"
            control={control}
            rules={{ required: promoCategoryTagOptions.length > 0 }}
            render={({ field }) => (
              <Grid item xs={4}>
                <Autocomplete
                  {...field}
                  value={promoCategoryTag}
                  options={promoCategoryTagOptions}
                  renderInput={(params) => <TextField required {...params} variant="outlined" label="Category" />}
                  onChange={(e: unknown, newValue: string | null) => {
                    field.onChange(newValue);
                    setPromoCategoryTag(newValue);
                    setPromoSubCategoryTag(null);
                    refreshMetaOptions(promoTags, promoDeptTag, newValue);
                  }}
                  componentsProps={boxShadowStyle}
                />
              </Grid>
            )}
          />
        )}
        {!promoMetaLoading && promoSubCategoryTagOptions.length > 0 && (
          <Controller
            key="sub_category_tag"
            name="sub_category_tag"
            rules={{ required: promoSubCategoryTagOptions.length > 0 }}
            control={control}
            render={({ field }) => (
              <Grid item xs={4}>
                <Autocomplete
                  {...field}
                  value={promoSubCategoryTag}
                  options={promoSubCategoryTagOptions}
                  renderInput={(params) => <TextField required {...params} variant="outlined" label="Sub Category" />}
                  onChange={(e: unknown, newValue: string | null) => {
                    field.onChange(newValue);
                    setPromoSubCategoryTag(newValue);
                  }}
                  componentsProps={boxShadowStyle}
                />
              </Grid>
            )}
          />
        )}

        <Grid item xs={12}>
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Grid>
        <Grid item xs={12}>
          {!promoMetaLoading ? (
            <PromoDiscountFormV4
              promoCode={promoCode}
              parentPromo={parentPromo}
              promoType={promoType}
              promoDiscountData={promoDiscountData}
              setPromoDiscountData={setPromoDiscountData}
              promoProductTypes={promoProductTypes}
              promoRegionGroupings={promoRegionGroupings}
              promoMetaLoading={promoMetaLoading}
              isDev={isDev}
              rerender={rerender}
              highlightedDiscountIds={selectedDiscountIds}
            />
          ) : (
            <Spinner />
          )}
        </Grid>
        <Grid item xs={12}>
          <PageSubheader title="Promo code restrictions" />
        </Grid>
        <Grid item xs={12}>
          {promoMeta && promoCode?.promo_conditions !== undefined && (
            <PromoConditions
              promoCode={promoCode}
              promoMeta={promoMeta}
              onConditionRowSelect={handleConditionRowSelect}
            />
          )}
        </Grid>
        <Controller
          key="expires_at"
          name="expires_at"
          control={control}
          render={({ field }) => (
            <Grid item xs={12}>
              <DateTimeWidget format="DD/MM/YYYY hh:mm a" label="Expiry Date" {...field} />
              <Typography variant="subtitle2">
                (There is a grace period of 48 hours - to stop use immediately - set the promo_toggle of "Disallow all
                promo requests")
              </Typography>
            </Grid>
          )}
        />
        <Controller
          key="code_limit"
          name="code_limit"
          control={control}
          rules={{ required: true, min: 1 }}
          render={({ field }) => (
            <Grid item xs={12}>
              <TextField
                required
                {...field}
                fullWidth
                label="Total Usage Limit"
                type="number"
                onChange={(e) => {
                  const targetValue = parseInt(e.target.value);
                  field.onChange(Math.max(targetValue, 1));
                }}
              />
            </Grid>
          )}
        />
        <Controller
          key="limit_per_user"
          name="limit_per_user"
          control={control}
          rules={{ required: true, min: 1 }}
          render={({ field }) => (
            <Grid item xs={12}>
              <TextField
                required
                {...field}
                fullWidth
                label="Usage Limit Per Customer"
                type="number"
                onChange={(e) => {
                  const targetValue = parseInt(e.target.value);
                  field.onChange(Math.max(targetValue, 1));
                }}
              />
            </Grid>
          )}
        />
        <Controller
          key="limit_per_user_per_year"
          name="limit_per_user_per_year"
          control={control}
          rules={{ required: false, min: 0 }}
          render={({ field }) => (
            <Grid item xs={12}>
              <TextField
                {...field}
                fullWidth
                label="Usage Limit Per Customer Per Year"
                type="number"
                onChange={(e) => {
                  const targetValue = parseInt(e.target.value);
                  field.onChange(Math.max(targetValue, 0));
                }}
              />
              <Typography variant="subtitle2">
                (Usage calculated based on purchases in the last 365 days - 0 applies no yearly limit)
              </Typography>
            </Grid>
          )}
        />
        <Grid container marginLeft={2}>
          <Controller
            key="first_time_purchase_only"
            name="first_time_purchase_only"
            control={control}
            render={({ field }) => (
              <Grid item xs={12} zeroMinWidth margin={0}>
                <FormControlLabel
                  control={<Checkbox {...field} defaultChecked={promoCode?.first_time_purchase_only} />}
                  label="First time purchase only"
                  title="If checked, only customers who have not placed an order can use the promo (brand specific)"
                />
              </Grid>
            )}
          />
          <Controller
            key="first_time_app_purchase_only"
            name="first_time_app_purchase_only"
            control={control}
            render={({ field }) => (
              <Grid item xs={12} zeroMinWidth margin={0}>
                <FormControlLabel
                  control={<Checkbox {...field} defaultChecked={promoCode?.first_time_app_purchase_only} />}
                  label="First time app purchase only"
                  title="If checked, only customers who have not placed an order in a mobile app can use the promo (brand specific)"
                />
              </Grid>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <PageSubheader title="Presentation" />
          <Box margin={2}>
            <Controller
              key="popup_message"
              name="popup_message"
              control={control}
              render={({ field }) => (
                <Grid item xs={12}>
                  <TextField {...field} fullWidth multiline label="Popup Message" />
                </Grid>
              )}
            />
          </Box>
          {promoCode?.popup_message_presented && (
            <Box margin={2}>
              <TextField
                value={promoCode?.popup_message_presented}
                disabled
                fullWidth
                multiline
                label="Popup Message"
              />
            </Box>
          )}
          {promoMeta && <PromoReplacementExplainer promoTextReplacements={promoMeta.promo_text_replacements} />}
        </Grid>
        <Box margin={2}>
          <Button variant="text" onClick={() => setShowAdvanced(!showAdvanced)}>
            {showAdvanced ? 'Hide ' : 'Show '} Advanced Restrictions
          </Button>
        </Box>
        {showAdvanced && (
          <>
            <Grid item xs={12}>
              {!promoMetaLoading && promoToggles.length > 0 && (
                <Controller
                  key="promo_toggles"
                  name="promo_toggles"
                  control={control}
                  render={({ field }) => (
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        options={promoToggles}
                        getOptionLabel={(option) =>
                          `${option.isActive ? '[INACTIVE] - ' : ''}${option.internalDescription}`
                        }
                        value={promoToggles.filter((option) => field?.value?.includes(option.key)) ?? []}
                        onChange={(event, newValue) => {
                          const selectedKeys = newValue.map((option) => option.key);
                          field.onChange(selectedKeys);
                        }}
                        renderInput={(params) => {
                          return <TextField {...params} variant="outlined" label="Toggles" />;
                        }}
                        renderOption={(props, option) => (
                          <li
                            {...props}
                            title={
                              option.isActive
                                ? option.internalDescriptionLong
                                : `Inactive - ${option.internalDescriptionLong}`
                            }
                          >
                            {`${!option.isActive ? '[INACTIVE] - ' : ''}${option.internalDescription}`}
                          </li>
                        )}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              key={option.key}
                              {...getTagProps({ index })}
                              label={`${!option.isActive ? '[INACTIVE] - ' : ''}${option.internalDescription}`}
                              title={
                                option.isActive
                                  ? option.internalDescriptionLong
                                  : `Inactive - this toggle will not be applied - ${option.internalDescriptionLong}`
                              }
                            />
                          ))
                        }
                        componentsProps={boxShadowStyle}
                      />
                    </Grid>
                  )}
                />
              )}
            </Grid>
            <Controller
              key="device_types"
              name="device_types"
              control={control}
              render={({ field }) => (
                <Grid item xs={12}>
                  <Autocomplete
                    {...field}
                    multiple
                    options={PROMO_DEVICE_TYPES.slice(1).map((type) => type.key)}
                    getOptionLabel={(option) => PROMO_DEVICE_TYPES.find((type) => type.key === option)?.value}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} variant="outlined" label="Restrict to Device Types"></TextField>
                    )}
                    onChange={(e: unknown, newValue: Array<string> | null) => {
                      field.onChange(newValue);
                    }}
                    // Styling the dropdown component
                    componentsProps={{
                      paper: {
                        sx: {
                          boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)`,
                        },
                      },
                    }}
                  />
                </Grid>
              )}
            />
            <Grid item xs={12}>
              <Controller
                key="allowed_payment_methods"
                name="allowed_payment_methods"
                control={control}
                render={({ field }) => (
                  <Grid item xs={12}>
                    <Autocomplete
                      {...field}
                      multiple
                      options={PaymentsServiceProviders.map((provider) => provider.value)}
                      getOptionLabel={(option) =>
                        PaymentsServiceProviders.find((payment) => payment.value === option)?.title
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Restrict to Payment Service Provider"
                        ></TextField>
                      )}
                      onChange={(e: unknown, newValue: Array<string> | null) => {
                        field.onChange(newValue);
                      }}
                      componentsProps={boxShadowStyle}
                    />
                  </Grid>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                key="email_addresses"
                name="email_addresses"
                control={control}
                render={({ field }) => (
                  <Tooltip title="Multiple email address(es) must be comma separated.">
                    <TextField {...field} fullWidth label="Restrict to Email Address(es)" />
                  </Tooltip>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                key="offer_whitelist"
                name="offer_whitelist"
                control={control}
                render={({ field }) => (
                  <Tooltip title="Multiple deal ID(s) must be comma separated.">
                    <TextField
                      {...field}
                      fullWidth
                      disabled={offerBlackList && offerBlackList.length > 0}
                      label="Restrict to Deal ID(s)"
                    />
                  </Tooltip>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                key="offer_blacklist"
                name="offer_blacklist"
                control={control}
                render={({ field }) => (
                  <Tooltip title="Multiple deal ID(s) must be comma separated.">
                    <TextField
                      {...field}
                      fullWidth
                      disabled={offerWhiteList && offerWhiteList.length > 0}
                      label="Exclude Deal ID(s)"
                    />
                  </Tooltip>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                key="bin_numbers"
                name="bin_numbers"
                control={control}
                render={({ field }) => (
                  <Tooltip title="You must restrict to at least Stripe and/or Stripe 3DS payment methods. Multiple BIN numbers must be comma separated.">
                    <TextField {...field} fullWidth label="Restrict to Bank Identification Number(s)" />
                  </Tooltip>
                )}
              />
            </Grid>
          </>
        )}
        {showAdvanced && (
          <>
            <Grid item xs={12}>
              <PageSubheader title="Corporate benefits" />
            </Grid>
            <Controller
              key="is_corporate"
              name="is_corporate"
              control={control}
              render={({ field }) => (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox disabled={!!promoCode} defaultChecked={isCorporateBenefitOn} {...field} />}
                    label="Corporate promo code"
                  />
                </Grid>
              )}
            />
            {isCorporateBenefitOn && (
              <Controller
                key="corporate_name"
                name="corporate_name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Grid item xs={12}>
                    <TextField disabled={!!promoCode} fullWidth required {...field} label="Company Name"></TextField>
                  </Grid>
                )}
              />
            )}
            {isCorporateBenefitOn && (
              <Controller
                key="email_domains"
                name="email_domains"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Grid item xs={12}>
                    <Tooltip title="Multiple email domains must be comma separated">
                      <TextField fullWidth required {...field} label="Eligible Email Domains" />
                    </Tooltip>
                  </Grid>
                )}
              />
            )}
          </>
        )}
        <Grid item xs={12}>
          <Button type="submit" variant="contained">
            Submit
          </Button>
          {submitProblems?.length > 0 && hasSubmitted && <Alert severity="error">{submitProblems}</Alert>}
        </Grid>
        {showAdvanced && !!promoCode && (
          <Grid item xs={12}>
            <PageSubheader title="Users">
              <Box component="span" color="text.secondary" alignItems="left">
                Users that qualify for this promo
              </Box>
            </PageSubheader>
            <PromoUsers promoId={promoCode?.id_promo_code} promoCode={promoCode} />
            <PromoUserBatchUpload codeName={promoCode?.code_name} promoExpiresAt={promoCode?.expires_at} />
          </Grid>
        )}
      </Grid>
    </form>
  );
}

export default PromoFormV3;
