import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import store from '~/configureStore';

import { setShowDisputeProcess } from '~/actions/dispute';

import { RootState } from '~/types/store';

export const getDisputeBoxStyle = (showDisputeProcess: boolean) => {
  return showDisputeProcess
    ? {
        border: 4,
        borderColor: 'red',
        borderStyle: 'solid',
        borderRadius: '4px',
      }
    : {};
};

export const selectShowDisputeProcess = (state: RootState) => state.dispute.showDisputeProcess;

export const getCurrentDisputeProcessState = () => store.getState().dispute.showDisputeProcess;

export const toggleDisputeProcess = (orderId: string) => {
  const currentState = getCurrentDisputeProcessState();
  const newState = !currentState;

  store.dispatch(setShowDisputeProcess(newState));

  if (newState) {
    requestAnimationFrame(() => {
      takeScreenshot(orderId);
    });
  }

  return newState;
};

export const takeScreenshot = async (orderId: string) => {
  try {
    const originalScrollPosition = {
      x: window.scrollX,
      y: window.scrollY,
    };

    window.scrollTo(0, 0);

    const fullHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight,
    );

    const fullWidth = Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.body.clientWidth,
      document.documentElement.clientWidth,
    );

    const canvas = await html2canvas(document.documentElement, {
      windowWidth: fullWidth,
      windowHeight: fullHeight,
      width: fullWidth,
      height: fullHeight,
      scrollX: 0,
      scrollY: 0,
      x: 0,
      y: 0,
      allowTaint: true,
      useCORS: true,
      logging: false,
      onclone: (clonedDoc) => {
        clonedDoc.documentElement.style.overflow = 'visible';
        clonedDoc.body.style.overflow = 'visible';
        clonedDoc.documentElement.style.height = `${fullHeight}px`;
        clonedDoc.body.style.height = `${fullHeight}px`;
      },
    });

    const imgData = canvas.toDataURL('image/jpeg', 0.5);
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
      compress: true,
    });

    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    let heightLeft = pdfHeight;
    let position = 0;

    pdf.addImage(imgData, 'JPEG', 0, position, pdfWidth, pdfHeight, undefined, 'FAST');
    heightLeft -= pdf.internal.pageSize.getHeight();

    while (heightLeft >= 0) {
      position -= pdf.internal.pageSize.getHeight();
      pdf.addPage();
      pdf.addImage(imgData, 'JPEG', 0, position, pdfWidth, pdfHeight, undefined, 'FAST');
      heightLeft -= pdf.internal.pageSize.getHeight();
    }

    pdf.save(`order-${orderId || 'screenshot'}.pdf`);

    window.scrollTo(originalScrollPosition.x, originalScrollPosition.y);
  } catch (error) {
    console.error('Error taking screenshot:', error);
    alert('Failed to generate PDF. Please try again.');
  }
};
