import React from 'react';

import { Chip, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { checkCanRedeemLuxPlusBenefits, isFreePreviewLuxPlusMember } from '~/components/Membership/utils';
import ReadOnlyField from '~/components/v2/common/ReadOnlyField/ReadOnlyField';

import { LUX_PLUS } from '~/consts/membership';

import { getDisputeBoxStyle } from '../utils/getDisputeProcess';

interface Props {
  customer: App.User;
  phoneNumber: string;
  postcode: string;
  subscriptions: App.MembershipSubscriptions | null;
  businessId?: string;
  businessInfo?: {
    businessName: string;
    businessCreditCustomerId?: string;
    employeePersonalAccountInfo: string;
    personalName: string;
    personalEmail: string;
  };
  showDisputeProcess?: boolean;
}

export default function CustomerInfo({
  customer,
  phoneNumber,
  postcode,
  subscriptions,
  businessId,
  businessInfo,
  showDisputeProcess,
}: Props) {
  return (
    <Stack id="overview" spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center" sx={{ paddingLeft: '8px' }}>
        <Typography variant="body2" fontWeight="bold">
          Customer info
        </Typography>

        {customer.shadow_ban_user && <Chip label="Shadowbanned" color="error" size="small" />}
        {customer.vip_client && <Chip label="VIP Client" color="warning" size="small" />}

        {checkCanRedeemLuxPlusBenefits(subscriptions) && (
          <Chip
            label={`${LUX_PLUS.PROGRAM_NAME} Member`}
            color="primary"
            size="small"
            sx={{ backgroundColor: '#314EAC' }}
          />
        )}
        {isFreePreviewLuxPlusMember(subscriptions) && (
          <Chip
            label={`Free Preview ${LUX_PLUS.PROGRAM_NAME} Member`}
            color="primary"
            size="small"
            sx={{ backgroundColor: '#314EAC' }}
          />
        )}
      </Stack>

      <Grid container spacing={2}>
        {!businessId && (
          <Grid md={3} sx={getDisputeBoxStyle(showDisputeProcess)}>
            <ReadOnlyField
              title="Name"
              value={customer.fullName}
              type="link"
              copyable
              href={`/users/${customer.memberId}`}
            />
          </Grid>
        )}
        {businessId && (
          <Grid md={3} sx={getDisputeBoxStyle(showDisputeProcess)}>
            <ReadOnlyField
              title="Name"
              value={businessInfo.businessCreditCustomerId ? businessInfo.personalName : customer.fullName}
              type="link"
              href={
                businessInfo.businessCreditCustomerId
                  ? `/users/${businessInfo.businessCreditCustomerId}`
                  : `/users/${customer.memberId}`
              }
            />
          </Grid>
        )}
        <Grid md={3}>
          <ReadOnlyField title="Email" value={businessInfo.personalEmail || customer.email} type="text" copyable />
        </Grid>
        <Grid md={3} sx={getDisputeBoxStyle(showDisputeProcess)}>
          <ReadOnlyField title="Phone" value={phoneNumber} type="text" copyable />
        </Grid>
        <Grid md={3}>
          <ReadOnlyField title="Postcode" value={postcode ?? 'N/A'} type="text" copyable />
        </Grid>
      </Grid>
    </Stack>
  );
}
