import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, ButtonGroup, Stack, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridEditInputCell,
  GridEditSingleSelectCell,
  GridRenderCellParams,
  GridRowClassNameParams,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid';
import { GridRowModesModelProps } from '@mui/x-data-grid/models/api/gridEditingApi';

import { definitions } from '@luxuryescapes/contract-svc-promo';
import { getRegions } from '@luxuryescapes/lib-regions';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import useQuery from '~/hooks/useQuery';

import {
  InternalPromoWithDiscounts,
  PromoProductTypes,
  PromoRegionGroupings,
  createOrUpdatePromoDiscount,
  deletePromoDiscount,
  getMinSpendProductTypeOptions,
  getProductTypeOptions,
} from '~/services/PromoService';

import { sortBy } from '~/utils/arrayUtils';
import currencyFormatter from '~/utils/currencyFormatter';
import { getCurrencyCodeByRegionCode } from '~/utils/getCurrencyCodeByRegionCode';

import HeaderInfo from '../Common/HeaderInfo';
import Spinner from '../Common/Spinner';

import MultiProductOptionSelect from './MultiProductOptionSelect';

/**
 * @deprecated (define in backend)
 */
const REGION_SORT_ORDER = [
  'Australia',
  'New Zealand',
  'United States',
  'United Kingdom',
  'Singapore',
  'India',
  'Germany',
  'Italy',
  'Canada',
  'China',
  'France',
  'Hong Kong',
  'Indonesia',
  'Ireland',
  'Israel',
  'Japan',
  'Korea',
  'Macau',
  'Malaysia',
  'Netherlands',
  'Philippines',
  'Qatar',
  'Russia',
  'Saudi Arabia',
  'South Africa',
  'Spain',
  'Taiwan',
  'Thailand',
  'United Arab Emirates',
  'Vietnam',
];

type RegionOption = {
  name: string;
  code: string;
  message?: string;
};

const getRegionOptions = (extraRegionOptions: PromoRegionGroupings): Array<RegionOption> => {
  return extraRegionOptions
    .map(
      (region): RegionOption => ({
        name: region.code,
        code: region.code,
        message: `${region.code} - includes ${region.regionCodes.join(', ')}`,
      }),
    )
    .concat(
      getRegions().map(
        (region): RegionOption => ({
          name: region.name,
          code: region.code,
        }),
      ),
    );
};

const getSortedRegions = (extraRegionOptions: PromoRegionGroupings) =>
  sortBy(
    getRegionOptions(extraRegionOptions),
    (region) => {
      const regionOrder = REGION_SORT_ORDER.indexOf(region.name);
      if (regionOrder === -1) {
        // Put it at the bottom of the list if it's not in the desired order
        return 999;
      }
      return regionOrder;
    },
    'asc',
  );

interface Props {
  promoCode: InternalPromoWithDiscounts;
  parentPromo?: InternalPromoWithDiscounts | null;
  isPromoAddDiscountView?: boolean;
  promoType: string;
  promoDiscountData: Array<App.PromoDiscount> | null;
  setPromoDiscountData: (discounts: Array<App.PromoDiscount>) => void;
  promoProductTypes: PromoProductTypes;
  promoRegionGroupings: PromoRegionGroupings;
  promoMetaLoading: boolean;
  isDev: boolean;
  rerender: () => void;
  highlightedDiscountIds?: string[];
}

type UpdateRowModesModelProps = {
  rowId: string;
  isCreate?: boolean;
  state: GridRowModesModelProps;
};

type DiscountObjectFormRow = InternalPromoWithDiscounts['promo_discounts'][0] & {
  isNew: boolean;
  isRegionGroupDiscount: boolean;
  id: string;
};

function PromoDiscountFormV4({
  promoCode,
  parentPromo,
  promoType,
  promoDiscountData,
  setPromoDiscountData,
  promoProductTypes,
  promoRegionGroupings,
  promoMetaLoading,
  isDev,
  rerender,
  highlightedDiscountIds,
}: Props) {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const updateRowModesModel = useCallback(({ rowId, isCreate, state }: UpdateRowModesModelProps) => {
    if (!rowId && !isCreate) {
      console.warn('updateRowModesModel no rowId', rowId, state);
    }

    const exists = rowModesModel[rowId];
    if (!exists && !isCreate) {
      console.error('updateRowModesModel rowId does not exist', rowId, state, rowModesModel);
    } else {
      setRowModesModel((prevModel) => ({
        ...prevModel,
        [rowId]: state,
      }));
    }
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const query = useQuery();
  // showAllProducts of true will show every possible product type option in the products type dropdown (including 'preferToHide' options)
  const showAllProducts = !!query.get('showAllProducts');

  const handleAddDiscount = () => {
    const id = uuid();

    const newDiscount: App.PromoDiscount = {
      id,
      id_promo_code: promoCode?.id_promo_code,
      id_promo_code_discount: id,
      products: ['ALL-ALL'],
      region: 'AU',
      subscription_item_discount_type: 'none',
      isNew: true,
      min_spend_products: [],
      discount_value: 0,
      max_discount: 0,
      min_spend: 0,
      modified_by: '',
      created_at: '',
      updated_at: '',
    };
    setPromoDiscountData([...(promoCode?.promo_discounts || []), newDiscount]);
    setTimeout(() => {
      updateRowModesModel({
        rowId: newDiscount.id_promo_code_discount,
        isCreate: true,
        state: { mode: GridRowModes.Edit, fieldToFocus: 'region' },
      });
    }, 200);
  };

  useEffect(() => {
    let hiddenFields: Array<string> = [];

    getProductTypeOptions(promoProductTypes, []).forEach((option) => {
      const matchingProductOptions = promoDiscountData.filter(
        (pd) => showAllProducts || pd.products.includes(option.value),
      );
      if (option?.preferToHide && matchingProductOptions.length === 0) {
        hiddenFields.push(option.value);
      } else {
        hiddenFields = hiddenFields.filter((hf) => hf !== option.value);
      }
    });
  }, [query, promoDiscountData, showAllProducts, promoProductTypes]);

  const handleCancelDiscount = useCallback(
    (params: GridRenderCellParams<DiscountObjectFormRow>) => {
      updateRowModesModel({
        rowId: params.row.id_promo_code_discount || params.row.id,
        state: { mode: GridRowModes.View, ignoreModifications: true },
      });
      const editedRow = promoDiscountData.find(
        (row) => row.id_promo_code_discount === params.row.id_promo_code_discount,
      );
      if (editedRow?.isNew) {
        setPromoDiscountData(
          promoDiscountData.filter((row) => row.id_promo_code_discount !== params.row.id_promo_code_discount),
        );
      }
    },
    [promoDiscountData, setPromoDiscountData],
  );

  const handleSaveDiscount = useCallback(
    (params: GridRenderCellParams<DiscountObjectFormRow>) => {
      updateRowModesModel({
        rowId: params.row.id_promo_code_discount,
        isCreate: true,
        state: { mode: GridRowModes.View },
      });
      updateRowModesModel({
        rowId: params.row.id,
        isCreate: true,
        state: { mode: GridRowModes.View },
      });
    },
    [setRowModesModel],
  );

  const handleEditDiscount = useCallback(
    (params: GridRenderCellParams<DiscountObjectFormRow>) => {
      updateRowModesModel({
        rowId: params.row.id_promo_code_discount,
        state: { mode: GridRowModes.Edit, fieldToFocus: 'region' },
      });
      updateRowModesModel({
        rowId: params.row.id,
        state: { mode: GridRowModes.Edit, fieldToFocus: 'region' },
      });
    },
    [updateRowModesModel],
  );

  const handleDeletePromoDiscount = useCallback(
    async (params) => {
      if (!promoCode) {
        setPromoDiscountData(promoDiscountData.filter((row) => row.id_promo_code_discount !== params.id));
        return;
      }

      let response;
      try {
        response = await deletePromoDiscount(params.id_promo_code, params.id_promo_code_discount);

        if (response.status == 200) {
          enqueueSnackbar('Discount deleted successfully', {
            variant: 'success',
          });
          rerender();
        } else {
          enqueueSnackbar(
            `Error deleting discount - ${'message' in response.body ? response.body.message : ''} - ${JSON.stringify(
              response.body,
            )}`,
            {
              variant: 'error',
            },
          );
        }
      } catch (err) {
        enqueueSnackbar(`Error deleting discount - ${JSON.stringify({ err, response }, null, 4)}`, {
          variant: 'error',
        });
      }
    },
    [promoCode, promoDiscountData, enqueueSnackbar, setPromoDiscountData],
  );

  const processRowUpdate = async (newRow) => {
    if (!newRow) {
      return;
    }
    const updatedRow = { ...newRow, isNew: false } as App.PromoDiscount;
    try {
      const payload: definitions['Internal Promo Discount'] = {
        id_promo_code: newRow.id_promo_code,
        id_promo_code_discount: newRow.id_promo_code_discount,
        discount_value: newRow.discount_value,
        max_discount: newRow.max_discount,
        min_spend: newRow.min_spend,
        products: updatedRow.products,
        subscription_item_discount_value: +newRow.subscription_item_discount_value,
        subscription_item_discount_type: newRow.subscription_item_discount_type,
        region: newRow.region,
        min_spend_products: newRow.min_spend_products,
        min_user_used_limit: newRow.min_user_used_limit,
      };

      // promoCode exists means this is not the create form -> update form.
      // We should only create or update promo discount when it is update form.
      // Promo Discount in the create form will be created only when promo code has been created.
      if (promoCode) {
        const results = await createOrUpdatePromoDiscount(payload);
        if (results.status == 200) {
          if (results.result.promoDiscount) {
            enqueueSnackbar('Discount updated successfully', {
              variant: 'success',
            });
          } else if (
            results.result.promoDiscounts &&
            (results.result.promoDiscounts as Array<App.PromoDiscount>).length > 0
          ) {
            if (promoRegionGroupings.filter((prg) => prg.code === updatedRow.region).length > 0) {
              enqueueSnackbar(results.message, {
                variant: 'success',
              });
            }
          } else {
            enqueueSnackbar('Discount not found', {
              variant: 'error',
            });
          }
          rerender();
        }
      } else {
        setPromoDiscountData(
          promoDiscountData.map((row) =>
            row.id_promo_code_discount === newRow.id_promo_code_discount ? updatedRow : row,
          ),
        );
      }

      return updatedRow;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`processRowUpdate DiscountFormV3`, {
        message: err.message,
        stack: err.stack,
        id: newRow.id_promo_code_discount,
        row: newRow,
      });
      updateRowModesModel({ rowId: newRow.id, state: { mode: GridRowModes.Edit } });
      enqueueSnackbar(err?.message, {
        autoHideDuration: 5000,
      });
    }
  };

  const getProductBKOption = (
    optionValue: string,
    promoProductTypes: PromoProductTypes,
    filterType: 'min_spend_products' | 'products',
  ) => {
    let match = null;
    switch (filterType) {
      case 'products':
        match = getProductTypeOptions(promoProductTypes, []).filter((o) => o.value === optionValue);
        break;
      case 'min_spend_products':
        match = getMinSpendProductTypeOptions(promoProductTypes, []).filter((o) => o.value === optionValue);
        break;
      default:
        console.warn(`Could not get getProductBKOption for value ${optionValue} and filterType ${filterType}`);
        break;
    }
    if (match && match.length > 0) {
      return {
        label: match[0].label,
        value: match[0].value,
      };
    } else {
      console.error('Fallback productBKOptions used, no display name found for productBK: ' + optionValue);
      return {
        label: optionValue,
        value: optionValue,
      };
    }
  };

  const SHOW_PRODUCT_LABELS_THRESHOLD = 3;

  const regionsOptions = getSortedRegions(promoRegionGroupings);

  const columns: Array<GridColDef<DiscountObjectFormRow>> = useMemo(() => {
    const sharedOpts: Partial<GridColDef> = {
      editable: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
    };
    return [
      {
        ...sharedOpts,
        field: 'region',
        headerName: 'Region',
        flex: 1,
        type: 'singleSelect',
        valueOptions: regionsOptions,
        title: (region: RegionOption) => region.message,
        getOptionLabel: (region: RegionOption) => region.name,
        getOptionValue: (region: RegionOption) => region.code,
        display: 'flex',
      },
      {
        ...sharedOpts,
        field: 'products',
        headerName: 'Product Type',
        align: 'left',
        styles: { maxHeight: '500px' },
        renderCell: (params) => {
          const productTitle =
            params.row.products?.length == 0
              ? 'None'
              : `${params.row.products
                  .map((p) => getProductBKOption(p, promoProductTypes, 'products'))
                  .map((o) => o.label)
                  .join('\n')}`;

          const allLabels = params.row.products.map((p) => getProductBKOption(p, promoProductTypes, 'products'));

          const showSummaryLabel = allLabels.length == 0 || allLabels.length >= SHOW_PRODUCT_LABELS_THRESHOLD;
          if (showSummaryLabel) {
            return (
              <Typography title={productTitle}>
                {allLabels?.length == 0 ? `None` : `${allLabels.length} Products Selected`}
              </Typography>
            );
          }

          return (
            <Box key={`${params.id}_${params.tabIndex}`} title={productTitle}>
              {allLabels.map((p) => (
                <Typography key={`${p.label}_${p.value}`}>{p.label}</Typography>
              ))}
            </Box>
          );
        },
        renderEditCell: (params) => {
          return (
            <MultiProductOptionSelect
              id={`${params.id}`}
              multiSelectType="products"
              value={params.row.products}
              promoProductTypes={promoProductTypes}
            />
          );
        },
        display: 'flex',
      },
      {
        ...sharedOpts,
        field: 'discount_value',
        headerName: `${promoType === 'percentage' ? 'Percentage' : 'Fixed'} Discount Value`,
        type: 'number',
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
      },
      {
        ...sharedOpts,
        field: 'max_discount',
        headerName: 'Max Discount',
        type: 'number',
        title: 'Discount for subscription items - does not apply to subscription item discounts',
        renderEditCell: (params) => (
          <GridEditInputCell
            {...params}
            inputProps={{
              min: 0,
              onKeyPress: (e) => {
                if (e.key === '-') {
                  e.preventDefault();
                }
              },
            }}
          />
        ),
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
      },
      {
        ...sharedOpts,
        field: 'subscription_item_discount_value',
        headerName: 'Subscription Discount',
        title: 'Discount for subscription items - will not apply contribute to max discount checks',
        type: 'number',
        renderCell: (params) => {
          if (
            params.row.subscription_item_discount_type === 'none' ||
            params.row.subscription_item_discount_value == 0
          ) {
            return '';
          }
          if (params.row.subscription_item_discount_type === 'percentage') {
            return `${params.row.subscription_item_discount_value}%`;
          }

          return currencyFormatter(
            getCurrencyCodeByRegionCode(params.row.region) ?? 'AUD',
            params.row.subscription_item_discount_value,
          );
        },
        renderEditCell: (params) => (
          <GridEditInputCell
            {...params}
            inputProps={{
              min: 1,
            }}
            fullWidth
          />
        ),
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
      },
      {
        ...sharedOpts,
        field: 'subscription_item_discount_type',
        headerName: 'Subscription Type',
        type: 'singleSelect',
        valueOptions: [
          { value: 'percentage', label: 'Percentage' },
          { value: 'fixed_amount', label: 'Fixed Amount' },
          { value: 'none', label: 'None' },
        ],

        renderEditCell: (params) => <GridEditSingleSelectCell {...params} fullWidth />,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
      },
      {
        ...sharedOpts,
        field: 'min_spend',
        headerName: 'Min Spend',
        type: 'number',
        renderHeader: () => (
          <HeaderInfo
            title="[Discount Restriction] - Min Spend - When set, only orders with this level of minimum spend will qualify for this discount. [See also 'Min Spend Product Types']"
            header="Min Spend"
            iconType="tick"
          />
        ),
        renderEditCell: (params) => (
          <GridEditInputCell
            {...params}
            inputProps={{
              min: 0,
              onKeyPress: (e) => {
                if (e.key === '-') {
                  e.preventDefault();
                }
              },
            }}
          />
        ),
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
      },
      {
        ...sharedOpts,
        field: 'min_spend_products',
        headerName: 'Min Spend Product Types',
        align: 'left',
        flex: 2,
        styles: { maxHeight: '500px' },
        renderHeader: () => (
          <HeaderInfo
            title="[Discount Restriction] - Min Spend Product Types - When determining the minimum spend, only these product types will count when considering if the customer qualifies for the discount."
            header="Min Spend Product Types"
            iconType="tick"
          />
        ),
        renderCell: (params: GridRenderCellParams<DiscountObjectFormRow>) => {
          if (!params.row.min_spend_products) {
            return null;
          }

          const productTitle =
            params.row.min_spend_products?.length == 0
              ? 'None'
              : `${params.row.min_spend_products
                  .map((p) => getProductBKOption(p, promoProductTypes, 'min_spend_products'))
                  .map((o) => `${o.label}`)
                  .join('\n')}`;

          const allLabels = params.row.min_spend_products.map((p) =>
            getProductBKOption(p, promoProductTypes, 'min_spend_products'),
          );

          const showSummaryLabel = allLabels.length == 0 || allLabels.length >= SHOW_PRODUCT_LABELS_THRESHOLD;
          if (showSummaryLabel) {
            <Typography>{allLabels?.length == 0 ? `None` : `${allLabels.length} Products Selected`}</Typography>;
          }

          return (
            <Box key={`${params.id}_${params.tabIndex}`} title={productTitle}>
              {allLabels.map((p) => (
                <Typography key={`${p.label}_${p.value}`}>{p.label}</Typography>
              ))}
            </Box>
          );
        },
        renderEditCell: (params) => {
          return (
            <MultiProductOptionSelect
              id={`${params.id}`}
              multiSelectType="min_spend_products"
              value={params.row.min_spend_products ?? []}
              promoProductTypes={promoProductTypes}
            />
          );
        },
        display: 'flex',
      },
      {
        ...sharedOpts,
        field: 'min_user_used_limit',
        headerName: 'Min User Used Limit',
        type: 'number',
        renderHeader: () => (
          <HeaderInfo
            title="[Discount Restriction] [BETA TESTING - TESTING UNDERWAY] - Min User Used Limit - When set, this promo must be used this many times before a user qualifies for this discount"
            header="Min User Used Limit"
            iconType="tick"
          />
        ),
        renderEditCell: (params) => <GridEditInputCell {...params} />,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
      },
      {
        ...sharedOpts,
        flex: 2,
        editable: false,
        field: 'action',
        headerName: 'Action',
        renderCell: (params: GridRenderCellParams<DiscountObjectFormRow>) => {
          if (rowModesModel[params.id]?.mode === GridRowModes.Edit) {
            return (
              <ButtonGroup variant="contained" size="small" disableElevation>
                <Button key={`${params.id}-save`} onClick={(e) => handleSaveDiscount(params)}>
                  Save
                </Button>
                <Button key={`${params.id}-cancel`} onClick={(e) => handleCancelDiscount(params)}>
                  Cancel
                </Button>
                {!params.row.isNew && (
                  <Button
                    key={`${params.id}-delete`}
                    color="error"
                    onClick={() => {
                      if (confirm('Are you sure you want to delete this discount row?')) {
                        handleDeletePromoDiscount(params.row);
                      }
                    }}
                  >
                    Delete
                  </Button>
                )}
              </ButtonGroup>
            );
          }
          return (
            <ButtonGroup variant="text" size="small">
              <Button key={`${params.id}-edit`} onClick={(e) => handleEditDiscount(params)}>
                Edit
              </Button>
            </ButtonGroup>
          );
        },
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
      },
    ];
  }, [
    regionsOptions,
    promoType,
    promoProductTypes,
    rowModesModel,
    handleSaveDiscount,
    handleCancelDiscount,
    handleDeletePromoDiscount,
    handleEditDiscount,
  ]);

  useEffect(() => {
    setPromoDiscountData(promoCode?.promo_discounts || []);
  }, [promoCode?.promo_discounts]);

  useEffect(() => {
    // Update row modes model whenever promoDiscountData changes
    const newModesModel = promoDiscountData.reduce((acc, discount) => {
      acc[discount.id_promo_code_discount || discount.id] = { mode: GridRowModes.View };
      return acc;
    }, rowModesModel);

    setRowModesModel(newModesModel);
  }, [promoDiscountData]);

  if (promoMetaLoading) {
    return <Spinner />;
  }

  const getRowClassName = (params: GridRowClassNameParams<DiscountObjectFormRow>): string => {
    if (highlightedDiscountIds?.includes(params.row.id_promo_code_discount)) {
      return 'highlighted-row';
    }

    if (!promoCode?.promo_warnings) {
      return '';
    }

    const error = promoCode?.promo_warnings?.filter(
      (pw) =>
        params?.row?.id_promo_code_discount === pw.id_promo_code_discount ||
        pw.id_promo_code_discounts?.includes(params?.row?.id_promo_code_discount),
    );

    if (error?.length == 0) {
      return '';
    }

    if (error[0].level == 'error') {
      return 'conflicting-row';
    }

    if (error[0].level == 'warning') {
      return 'warning-row';
    }

    console.warn('PromoDiscountFormV4:getRowClassName:unknown-error-level', error);
    return 'warning-row';
  };

  return (
    <Box>
      <PageSubheader title="Add discount">
        <Stack direction="row" gap={2}>
          <Button onClick={() => handleAddDiscount()} variant="contained" size="small" startIcon={<AddIcon />}>
            Add Discount
          </Button>
        </Stack>
        {isDev && (
          <>
            <pre>
              <code>
                <details>
                  <summary>promoDiscountData</summary>
                  {JSON.stringify(promoDiscountData, null, 2)}
                </details>
              </code>
            </pre>
            <pre>
              <code>
                <details>
                  <summary>promoCode.promo_discounts</summary>
                  {JSON.stringify(promoCode?.promo_discounts, null, 2)}
                </details>
              </code>
            </pre>
            <pre>
              <code>
                <details>
                  <summary>rowModesModel</summary>
                  {JSON.stringify(rowModesModel, null, 2)}
                </details>
              </code>
            </pre>
          </>
        )}
      </PageSubheader>
      {promoDiscountData && (
        <Box mt={3}>
          <DataGrid
            columns={columns}
            sx={{
              '.MuiDataGrid-row.highlighted-row': {
                bgcolor: 'primary.light',
                '&:hover': {
                  bgcolor: 'primary.main',
                },
              },
              '.MuiDataGrid-row.conflicting-row': {
                bgcolor: 'error.light',
                '&:hover': {
                  bgcolor: 'error.main',
                },
              },
              '.MuiDataGrid-row.warning-row': {
                bgcolor: 'warning.light',
                '&:hover': {
                  bgcolor: 'warning.main',
                },
              },
            }}
            getRowClassName={getRowClassName}
            editMode="row"
            autoHeight
            rows={promoDiscountData || []}
            getRowId={(row) => {
              if (!row) {
                // eslint-disable-next-line no-console
                console.groupCollapsed('PromoDiscountFormV4:invalidRow');
                console.error(
                  'The PromoDiscountFormV4s mui form attempted to getRowId and the row was null. This is unexpected',
                );
                // eslint-disable-next-line no-console
                console.log('current promoDiscountData', promoDiscountData);
                // eslint-disable-next-line no-console
                console.groupEnd();
                return uuid();
              }
              return row['id_promo_code_discount'] || row['id'];
            }}
            rowModesModel={rowModesModel}
            pageSizeOptions={[100]}
            initialState={{ pagination: { paginationModel: { pageSize: 100, page: 0 } } }}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={(error) => {
              console.error('Row update error:', error);
              enqueueSnackbar(error.message, { autoHideDuration: 5000 });
            }}
            disableColumnSorting={false}
            getRowHeight={() => 'auto'}
          />
        </Box>
      )}
    </Box>
  );
}

export default PromoDiscountFormV4;
