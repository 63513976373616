import qs from 'qs';

import {
  PaginatedPriceParrotQueryParams,
  PaginatedPriceParrotResponse,
  PriceSummaryJob,
  PriceSummaryJobBody,
  PriceSummaryJobQueryParams,
  Property,
  ScheduledPriceSummaryJob,
  ScheduledPriceSummaryJobBody,
  ScheduledPriceSummaryJobRun,
  ScheduledPriceSummaryJobVariation,
  ScheduledPriceSummaryJobVariationBody,
} from '~/types/services/priceParrot';

import { json_headers, request } from './common';

function basePath() {
  return window.configs.API_HOST + '/api/price-parrot';
}

function getPriceSummaryJobs(
  queryParams: PriceSummaryJobQueryParams,
): Promise<PaginatedPriceParrotResponse<PriceSummaryJob>> {
  return request(`${basePath()}/price-summary/jobs?${qs.stringify(queryParams)}`, {
    method: 'GET',
    headers: json_headers,
  });
}

function postPriceSummaryJob(priceSummaryBody: PriceSummaryJobBody): Promise<{ result: { job_id: string } }> {
  return request(`${basePath()}/price-summary/jobs`, {
    headers: json_headers,
    method: 'POST',
    body: JSON.stringify(priceSummaryBody),
  });
}

function downloadPriceSummaryJob(jobId: string): Promise<Response> {
  return request(`${basePath()}/price-summary/jobs/${jobId}/download`, {
    returnResponse: true,
    method: 'GET',
  });
}

function getEventSourceURL(): string {
  return `${basePath()}/price-summary/jobs/status/stream`;
}

function cancelPriceSummaryJob(jobId: string): Promise<void> {
  return request(`${basePath()}/price-summary/jobs/${jobId}/cancel`, {
    method: 'POST',
    headers: json_headers,
  });
}

function getScheduledPriceSummaryJobs(
  queryParams: PaginatedPriceParrotQueryParams,
): Promise<PaginatedPriceParrotResponse<ScheduledPriceSummaryJob>> {
  return request(`${basePath()}/scheduled-price-summary/jobs?${qs.stringify(queryParams)}`, {
    method: 'GET',
    headers: json_headers,
  });
}

function postScheduledPriceSummaryJob(body: ScheduledPriceSummaryJobBody): Promise<{ job: ScheduledPriceSummaryJob }> {
  return request(`${basePath()}/scheduled-price-summary/jobs`, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify(body),
  });
}

function patchScheduledPriceSummaryJob(
  jobId: number,
  body: Pick<ScheduledPriceSummaryJob, 'status' | 'scheduleInterval'>,
): Promise<{ job: ScheduledPriceSummaryJob }> {
  return request(`${basePath()}/scheduled-price-summary/jobs/${jobId}`, {
    method: 'PATCH',
    headers: json_headers,
    body: JSON.stringify(body),
  });
}

function getScheduledPriceSummaryJob(jobId: string): Promise<{ data: ScheduledPriceSummaryJob }> {
  return request(`${basePath()}/scheduled-price-summary/jobs/${jobId}`, { method: 'GET', headers: json_headers });
}

function postScheduledPriceSummaryJobVariation(
  jobId: number,
  body: ScheduledPriceSummaryJobVariationBody,
): Promise<{ data: ScheduledPriceSummaryJobVariation }> {
  return request(`${basePath()}/scheduled-price-summary/jobs/${jobId}/variations`, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify(body),
  });
}

function deleteScheduledPriceSummaryJobVariation(jobId: number, variationId: number): Promise<void> {
  return request(`${basePath()}/scheduled-price-summary/jobs/${jobId}/variations/${variationId}`, {
    method: 'DELETE',
    headers: json_headers,
  });
}

function getScheduledPriceSummaryJobRuns(jobId: string): Promise<{ data: Array<ScheduledPriceSummaryJobRun> }> {
  return request(`${basePath()}/scheduled-price-summary/jobs/${jobId}/runs`, {
    method: 'GET',
    headers: json_headers,
  });
}

function downloadParityReport(): Promise<Response> {
  return request(`${basePath()}/scheduled-price-summary/parity/download`, {
    returnResponse: true,
    method: 'GET',
  });
}

function searchProperty(search: string): Promise<{ data: Array<Property> }> {
  return request(`${basePath()}/property/search?${qs.stringify({ search })}`, {
    method: 'GET',
    headers: json_headers,
  });
}

export default {
  getPriceSummaryJobs,
  postPriceSummaryJob,
  downloadPriceSummaryJob,
  getEventSourceURL,
  cancelPriceSummaryJob,
  getScheduledPriceSummaryJobs,
  postScheduledPriceSummaryJob,
  patchScheduledPriceSummaryJob,
  getScheduledPriceSummaryJob,
  postScheduledPriceSummaryJobVariation,
  deleteScheduledPriceSummaryJobVariation,
  getScheduledPriceSummaryJobRuns,
  downloadParityReport,
  searchProperty,
};
