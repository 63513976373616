import React from 'react';

import { Checkbox, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';

import { definitions } from '@luxuryescapes/contract-svc-promo';

type MultiDiscountSelectProps = {
  id: string;
  value: Array<string>;
  promoDiscounts: definitions['InternalPromoWithDiscounts']['promo_discounts'];
  multiSelectType: 'promo_code_discount_ids';
};

export const renderMultiDiscountValue = (
  selected: Array<string>,
  promoDiscounts: definitions['InternalPromoWithDiscounts']['promo_discounts'],
): string => {
  if (selected.length === 1) {
    const match = promoDiscounts.find((opt) => opt.id_promo_code_discount === selected[0]);
    return match?.region + ' - ' + match?.discount_value;
  } else if (selected.length == 0) {
    return '';
  } else {
    return `${selected.length} Discounts Selected`;
  }
};

function MultiDiscountSelect({ id, value, promoDiscounts, multiSelectType }: MultiDiscountSelectProps) {
  const apiRef = useGridApiContext();

  const handleValueChange = (event: SelectChangeEvent<Array<string>>) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field: multiSelectType, value: newValue });
  };

  const discountOptions = promoDiscounts.map((opt) => ({
    isChecked: value.includes(opt.id_promo_code_discount),
    label: `${opt.region} - ${opt.discount_value}`,
    value: opt.id_promo_code_discount,
  }));

  return (
    <Select
      labelId={multiSelectType}
      id={multiSelectType}
      multiple
      value={value}
      placeholder="Select Discounts"
      onChange={handleValueChange}
      renderValue={(selected) => renderMultiDiscountValue(selected, promoDiscounts)}
      fullWidth
    >
      {discountOptions.map((opt, i) => (
        <MenuItem key={`${i}_${opt.value}`} value={opt.value}>
          <Checkbox checked={opt.isChecked} />
          <ListItemText primary={opt.label} />
        </MenuItem>
      ))}
    </Select>
  );
}

export default MultiDiscountSelect;
